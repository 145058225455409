<template>
    <div id="setting-screen">
     <div id="project-name">
       <span>DMX Lighting 2.0 Demo</span>
     </div>
     <p class="title">Advance Settings</p>
    <div id="advancedSettings">
    <div class="setting d-flex">
        <img src="../assets/images/failure.png"/>
        <span>Failure Detection</span>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="failureCheck" v-model="failure" :checked="failure">
        </div>
    </div>
     <div class="setting d-flex">
        <img src="../assets/images/notification.png"/>
        <span>Send Notification</span>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="failureCheck" v-model="notification" :checked="notification">
        </div>
    </div>
     <div class="setting d-flex">
        <img src="../assets/images/energy.png"/>
        <span>Energy Display</span>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="failureCheck" v-model="energy_display" :checked="energy_display">
        </div>
    </div>
    </div>
      <button class="confirmBtn" @click="updateSetting">Confirm</button>

  </div>
</template>

<script >
import { defineComponent } from "vue";
import appService from "../services/app";

export default defineComponent({
  name: "Login",
  mounted() {
      this.getAppStatus();
  },
  data() {
    return {
        failure:true,
        notification: true,
        energy_display:false
    };
  },
  methods:{
      async getAppStatus (){
          let result = await appService.getAppStatus();
          console.log(result);
          this.failure = result.failure_detection;
          this.notification = result.send_notification;
          this.energy_display = result.energy_display;
      },
      async updateSetting() {
      let body = {
            "failure_detection": Boolean(this.failure) 
        }
        // console.log(body);

      let result = await appService.updateSetting(body);
    //   console.log(result);
    //     //

      if (result) {
             window.location.href = '/';
      }
    }
  }
});
</script>

<style lang="scss" module></style>